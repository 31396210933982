import React, { useEffect, useState } from "react";
import BonumLogo from "assets/images/bonum_logo.png";
import { Image, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  RiUserVoiceFill,
  RiUser3Fill,
  RiBuilding2Fill,
  RiMailFill,
  RiSurveyFill,
  RiBookFill,
} from "react-icons/ri";
import { BiBrain } from "react-icons/bi";
import { Menu } from "antd";
import useFetchAndLoad from "hooks/useFetchAndLoad";
import Loading from "components/Loading";
import { googleAuth } from "services/coach.service";

function Sidebar() {
  const navigate = useNavigate();

  const [googleLoginURL, setGoogleLoginURL] = useState("");

  const { loading, callEndpoint } = useFetchAndLoad();

  const getGoogleLogin = async () => {
    const { data } = await callEndpoint(googleAuth());

    console.log(data.data);

    setGoogleLoginURL(data.data);
  };

  useEffect(() => {
    getGoogleLogin();
  }, []);

  // if (loading) return <Loading title="Cargando" />;

  return (
    <div className="Sidebar">
      <Image
        src={BonumLogo}
        className="Sidebar__logo"
        style={{ marginTop: 20, marginLeft: 20 }}
        onClick={() => navigate("/")}
      />

      <Menu
        mode="inline"
        style={{ height: "100%", borderRight: 0, background: "transparent" }}
        items={[
          {
            key: 0,
            label: (
              <Button
                className="Sidebar__button"
                leftIcon={<RiUserVoiceFill />}
                onClick={() => navigate("/coaches")}
              >
                Coaches
              </Button>
            ),
          },
          {
            key: 1,
            label: (
              <Button
                className="Sidebar__button"
                leftIcon={<RiUser3Fill />}
                onClick={() => navigate("/coachees")}
              >
                Coachees
              </Button>
            ),
          },
          {
            key: 2,
            label: (
              <Button
                className="Sidebar__button"
                leftIcon={<RiBookFill />}
                onClick={() => navigate("/sessionsSection")}
              >
                Sesiones
              </Button>
            ),
            children: [
              {
                style: { background: "transparent" },
                key: 3,
                label: (
                  <Button
                    className="Sidebar__button"
                    leftIcon={<RiBookFill />}
                    onClick={() => navigate("/sessionsSection/noShow")}
                  >
                    NoShow
                  </Button>
                ),
              },
            ],
          },
          {
            key: 4,
            label: (
              <Button
                className="Sidebar__button"
                leftIcon={<RiUser3Fill />}
                onClick={() => navigate("/focusareas")}
              >
                Areas de foco
              </Button>
            ),
          },
          {
            key: 5,
            label: (
              <Button
                className="Sidebar__button"
                leftIcon={<RiBookFill />}
                onClick={() => navigate("/programs")}
              >
                Programas
              </Button>
            ),
          },
          {
            key: 6,
            label: (
              <Button
                className="Sidebar__button"
                leftIcon={<RiBuilding2Fill />}
                onClick={() => navigate("/companies")}
              >
                Companies
              </Button>
            ),
            children: [
              {
                style: { background: "transparent" },
                key: 7,
                label: (
                  <Button
                    className="Sidebar__button"
                    leftIcon={<RiBuilding2Fill />}
                    onClick={() => navigate("/cohorts")}
                  >
                    Cohort
                  </Button>
                ),
              },
            ],
          },
          {
            key: 8,
            label: (
              <Button
                className="Sidebar__button"
                leftIcon={<RiUser3Fill />}
                onClick={() => navigate("/admins")}
              >
                Configuracion
              </Button>
            ),
            children: [
              {
                style: { background: "transparent" },
                key: 9,
                label: (
                  <Button
                    className="Sidebar__button"
                    leftIcon={<RiBuilding2Fill />}
                    onClick={() => navigate("/admins")}
                  >
                    Admins
                  </Button>
                ),
              },
              {
                style: { background: "transparent" },
                key: 10,
                label: (
                  <Button
                    className="Sidebar__button"
                    leftIcon={<RiBuilding2Fill />}
                    onClick={() => navigate("/questions")}
                  >
                    Preguntas
                  </Button>
                ),
              },
              {
                style: { background: "transparent" },
                key: 11,
                label: (
                  <Button
                    className="Sidebar__button"
                    leftIcon={<RiMailFill />}
                    onClick={() => navigate("/emails")}
                  >
                    Emails
                  </Button>
                ),
              },
            ],
          },
          {
            style: { background: "transparent" },
            key: 12,
            label: (
              <Button
                className="Sidebar__button"
                leftIcon={<BiBrain />}
                onClick={() => navigate("/reports")}
              >
                Reportes IA
              </Button>
            ),
          },
          {
            style: { background: "transparent" },
            key: 13,
            label: (
              <Button
                className="Sidebar__button"
                leftIcon={<RiSurveyFill />}
                onClick={() => navigate("/midnps")}
              >
                Mid NPS
              </Button>
            ),
          },
          {
            style: { background: "transparent" },
            key: 14,
            label: (
              <Button
                className="Sidebar__button"
                leftIcon={<RiSurveyFill />}
                onClick={() => navigate("/finalnps")}
              >
                Final NPS
              </Button>
            ),
          },
          {
            key: 15,
            label: (
              <Button
                className="Sidebar__button"
                leftIcon={<RiBookFill />}
                onClick={() => navigate("/sessionsSection")}
              >
                Bonum Mentoring
              </Button>
            ),
            children: [
              {
                style: { background: "transparent" },
                key: 16,
                label: (
                  <Button
                    className="Sidebar__button"
                    leftIcon={<RiUser3Fill />}
                    onClick={() => navigate("/mentor")}
                  >
                    Mentores
                  </Button>
                ),
              },
              {
                style: { background: "transparent" },
                key: 17,
                label: (
                  <Button
                    className="Sidebar__button"
                    leftIcon={<RiUser3Fill />}
                    onClick={() => navigate("/mentee")}
                  >
                    Mentees
                  </Button>
                ),
              },
              {
                style: { background: "transparent" },
                key: 18,
                label: (
                  <Button
                    className="Sidebar__button"
                    leftIcon={<RiUser3Fill />}
                    onClick={() => navigate("/mentoringevaluations")}
                  >
                    Evaluaciones
                  </Button>
                ),
              },
            ],
          },
          // {
          //   key: 11,
          //   label: (
          //     <Button
          //       className="Sidebar__button"
          //       leftIcon={<RiAdminFill />}
          //       onClick={() => getGoogleLogin()}
          //     >
          //       Admin
          //     </Button>
          //   ),
          //   children: [
          //     {
          //       style: { background: "transparent" },
          //       key: 12,
          //       label: (
          //         <Button
          //           className="Sidebar__button"
          //           leftIcon={<RiLockPasswordFill />}
          //           onClick={() => window.open(googleLoginURL, "_blank")}
          //         >
          //           Generar Token
          //         </Button>
          //       ),
          //     },
          //   ],
          // },
        ]}
      ></Menu>
    </div>
  );
}

export default Sidebar;
